import * as Sentry from '@sentry/browser'
import jwtDefaultConfig from './jwtDefaultConfig'
import axios from '../../../auth/axios'
import i18n from '../../../configs/i18n'

export default class JwtService {
    // ** jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    subscribers = []

    constructor(jwtOverrideConfig) {
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
        // ** Request Interceptor
        axios.interceptors.request.use(
            config => {
                // ** Get token from localStorage
                const accessToken = this.getToken()
                config.headers = {
                    'Access-Control-Allow-Origin': "'*'",
                    'Accept-Language': ` ${i18n.language}, ${navigator.language}`
                }
                if (config.url !== '/token/refresh' && accessToken) {
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                }
                return config
            },
            error => Promise.reject(error)
        )

        // ** Add request/response interceptor
        axios.interceptors.response.use(
            response => response,
            error => {
                // ** const { config, response: { status } } = error
                const { config, response } = error
                const originalRequest = config
                // ** if (status === 401) {
                if (response && response.status === 401) {
                    if (response.request.responseURL.includes('token/refresh')) {
                        this.logOut()
                    }
                    const errors = response.data.errors
                    if (errors.length > 0) {
                        if (errors[0].message === 'Full authentication is required to access this resource.') {
                            this.logOut()
                            return
                        }
                    }
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        this.refreshToken()
                            .then(r => {
                                this.isAlreadyFetchingAccessToken = false
                                this.setToken(r.data.token)
                                this.setRefreshToken(r.data.refreshToken)

                                const response = JSON.stringify(r.data)
                                Sentry.setUser(response.user)
                                localStorage.setItem('user', response)
                                this.onAccessTokenFetched(r.data.token)
                            })
                            .catch(() => {
                                this.logOut()
                            })
                    }
                    return new Promise(resolve => {
                        this.addSubscriber(accessToken => {
                            // ** Make sure to assign accessToken according to your response.
                            // ** Check: https://pixinvent.ticksy.com/ticket/2413870
                            // ** Change Authorization header
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                            resolve(axios(originalRequest))
                        })
                    })
                } else if (response && response.status === 401) {
                    const errors = response.data.errors
                    if (errors.length > 0) {
                        this.logOut()
                    }
                }
                return Promise.reject(error)
            }
        )
    }

    //#region all
    logOut() {
        localStorage.removeItem('user')
        localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
        window.history.go()
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
        return axios.post(this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return axios.post(this.jwtConfig.registerEndpoint, ...args)
    }

    refreshToken() {
        const refreshToken = this.getRefreshToken()
        return axios.post(this.jwtConfig.refreshEndpoint, {
            refreshToken
        })
    }

    getOrganisations() {
        return axios.get(this.jwtConfig.getOrganisationEndpoint)
    }

    getPlayers() {
        return axios.get(this.jwtConfig.getPlayerEndpoint)
    }

    getTypes(lang = null) {
        if (lang) {
            return axios.get('types?lang=' + lang)
        } else {
            return axios.get('types')
        }
    }

    addNewOrganisation(...args) {
        return axios.post(this.jwtConfig.postOrganisationEndpoint, ...args)
    }

    addNewPlayer(...args) {
        return axios.post(this.jwtConfig.postPlayerEndpoint, ...args)
    }

    getFormMartialArtsEvent(id) {
        return axios.get(this.jwtConfig.getFormMartialArtsEventEndpoint + id)
    }

    getMartialArtsEventResult(id) {
        return axios.get(`/martial-arts/form/${id}/result`)
    }

    createNewMartialArtsEvent(...args) {
        return axios.post(this.jwtConfig.postFormMartialArtsEventEndpoint, ...args)
    }

    updateMartialArtsEvent(id, ...args) {
        return axios.put(`/martial-arts/form/${id}`, ...args)
    }

    putMartialArtsEventResult(id, ...args) {
        return axios.post(`/martial-arts/form/${id}/result`, ...args)
    }

    getMartialArtsEvents(args) {
        return axios.get(this.jwtConfig.getMartialArtsEventsEndpoint, { params: args })
    }
    searchMartialArtsEvents(args) {
        return axios.get(this.jwtConfig.searchMartialArtsEventsEndpoint, { params: args })
    }

    getAllTypes() {
        return axios.get(this.jwtConfig.getAllTypesEndpoint)
    }

    postTypeLang(id, ...args) {
        return axios.post(`/type/${id}/lang`, ...args)
    }

    editType(id, ...args) {
        return axios.put(`/type/${id}`, ...args)
    }

    getSoccerTeams(args) {
        return axios.get('/soccer/teams', { params: args })
    }

    updateSoccerTeam(id, args) {
        return axios.put(`/soccer/team/${id}`, { ...args })
    }
    postTeamLang(id, ...args) {
        return axios.post(`/soccer/team/${id}/lang`, ...args)
    }

    getFeed(page = 1, limit = 25, feedType = 'feeds-for-you') {
        let url = `/feeds?page=${page}&limit=${limit}`

        if (feedType === 'feeds-from-followers') {
            url = `/feedsFromFollowers?page=${page}&limit=${limit}`
        }

        if (feedType === 'feeds-hot-for-you') {
            url = `/feedsHotForYou?page=${page}&limit=${limit}`
        }

        return axios.get(url)
    }

    getSports(allSports = false) {
        return axios.get('/sports', { params: { allSports } })
    }

    getCountires() {
        return axios.get('/countries')
    }

    getSportsLeague(sport, parameters) {
        return axios.get(`/sports/${sport}/leagues`, { params: parameters })
    }

    getSport() {
        return axios.get('sports/events')
    }

    getEvents(sport, id, page, limit, date) {
        if (sport === 'basketball' || sport === 'hockey') {
            return axios.get(`/${sport}/events?page=${page}&limit=${limit}&leaguesGoalServeId=${id}&endDay=${date}`)
        }
        return axios.get(`/${sport}/events?page=${page}&limit=${limit}&leagues=${id}&endDay=${date}`)
    }


    addCustomCoupon(...args) {
        return axios.post('/coupon/add_custom', ...args)
    }

    addCoupon(...args) {
        return axios.post('/coupon/add', ...args)
    }

    getCoupons(page = 1, limit = 25) {
        return axios.get(`/coupons?page=${page}&limit=${limit}`)
    }

    searchEvents(sport, search, page, limit, date) {
        return axios.get(`/${sport}/events?name=${search}&page=${page}&limit=${limit}&endDay=${date}`)
    }

    getEvent(sport, id) {
        return axios.get(`/${sport}/events/${id}`)
    }

    getPopular(page, limit) {
        return axios.get(`/soccer/popular?page=${page}&limit=${limit}`)
    }

    changeLanguage(code) {
        return axios.get(`/user/changeLang/${code}`)
    }

    getUserStats(id) {
        return axios.get(`/users/${id}/stats_basic`)
    }

    getUserFeed(id, page, limit, status = null) {
        let url = `/users/${id}/feed?page=${page}&limit=${limit}`

        if (status && status.value !== 'all') url = url.concat(`&status=${status.value}`)

        return axios.get(url)
    }

    getLikeUserFeed(id, page = 1, limit = 25) {
        return axios.get(`/feeds/${id}/likes?page=${page}&limit=${limit}`)
    }
    likeUserFeed(id) {
        return axios.post(`/feeds/${id}/like`)
    }

    unlikeUserFeed(id) {
        return axios.delete(`/feeds/${id}/like`)
    }

    getMyUser() {
        return axios.get('/user')
    }

    getUser(id) {
        return axios.post(`/users/${id}`)
    }

    followUser(id) {
        return axios.post(`/users/${id}/follow`)
    }
    getFollowersUsers(id, page = 1, limit = 25) {
        return axios.get(`/users/${id}/followers?page=${page}&limit=${limit}`)
    }
    getFollowingUsers(id, page = 1, limit = 25) {
        return axios.get(`/users/${id}/following?page=${page}&limit=${limit}`)
    }
    unfollowUser(id) {
        return axios.delete(`/users/${id}/follow`)
    }

    updateUserProfile(...args) {
        return axios.put('/user', ...args)
    }

    updatePhoneNumber(...args) {
        return axios.put('/user/setPhone', ...args)
    }

    verifyPhoneNumber(code) {
        return axios.get(`/user/phone/validate?code=${code}`)
    }

    getLimits() {
        return axios.get('/user/limits')
    }

    changePassword(...args) {
        return axios.put('user/changePassword', ...args)
    }

    changeCurrency(...args) {
        return axios.put('/user/setCurrency', ...args)
    }

    getCurrency() {
        return axios.get('/currencies')
    }

    getImpersonateUserToken(email) {
        return axios.get(`/admin/user/generateToken/${email}`)
    }

    getProfiles() {
        return axios.get('/user/profiles')
    }

    getSomeUserProfiles(id, isPriv = false) {
        return axios.get(`/users/${id}/profiles?isPrivate=${isPriv}`)
    }

    getDefaultTax() {
        return axios.get('/user/defaultTax')
    }

    setActiveProfile(id) {
        return axios.get(`/profiles/${id}/activate`)
    }

    getProfileId(id) {
        return axios.get(`/profiles/${id}`)
    }

    removeProfile(id) {
        return axios.delete(`/profiles/${id}`)
    }

    createProfile(...args) {
        return axios.post(`/profiles`, ...args)
    }

    updateProfile(id, ...args) {
        return axios.put(`/profiles/${id}`, ...args)
    }

    getPaidProfileSubscriptions() {
        return axios.get(`/user/paidUsers`)
    }

    getTaxView() {
        return axios.get('/user/taxView')
    }
    putTaxView(...args) {
        return axios.put('/user/taxView', ...args)
    }

    getSmses(page = 1, limit = 10) {
        return axios.get(`/user/smses?page=${page}&limit=${limit}`)
    }

    getNotificationSettings() {
        return axios.get('/notificationSettings')
    }

    changeNotificationSettings(id, ...args) {
        return axios.put(`/notificationSettings/${id}`, ...args)
    }
    //#endregion

    //#region  notification
    getNotifications(page = 1, limit = 25) {
        return axios.get(`/notifications?page=${page}&limit=${limit}`)
    }

    setAsReadNotification(id) {
        return axios.post(`notifications/setRead/${id}`)
    }

    setAsReadAllNotifications() {
        return axios.post('notifications/setAllRead')
    }

    //#endregion

    getFeedByCouponId(couponId) {
        return axios.get(`feeds?couponId=${couponId}`)
    }

    getSingleCoupon(id, couponId) {
        return axios.get(`users/${id}/feed?couponId=${couponId}`)
    }

    getSingleFeed(id) {
        return axios.get(`feeds/${id}`)
    }

    //#region User stats
    getUserYieldPerMonth(userId) {
       return axios.get(`/users/${userId}/yieldPerMonth`)
    }

    getUsersSportsStats(userId) {
        return axios.get(`/users/${userId}/sportsTable`)
    }

    getUsersStatsYieldPerMonthTable(userId) {
        return axios.get(`/users/${userId}/yieldTablePerMonth`)
    }

    getUsersStatsByCreatedAt(userId) {
        return axios.get(`/users/${userId}/statsByCreatedAt`)
    }

    getUsersStatsByCouponType(userId) {
        return axios.get(`/users/${userId}/statsByCouponType`)
    }

    getUsersStatsByOddsRange(userId) {
        return axios.get(`/users/${userId}/statsByOddsRange`)
    }

    getUsersStatsByAccessType(userId) {
        return axios.get(`/users/${userId}/statsByAccessType`)
    }
    //#endregion

    //#region feedTop
    getPopularUsers() {
        return axios.get('/popularUsers')
    }

    getTopTipsters() {
        return axios.get('/topTipsters')
    }

    getPaidTipsters() {
        return axios.get('/paidTipsters')
    }
    //#endregion

    setAvatar(file) {
        return axios.post('/user/avatar', file, {
            headers: {
                'content-type': file.type,
                'content-length': `${file.size}`
            }
        })
    }

    deleteUser() {
        return axios.delete('/user')
    }

    clearStatistics() {
        return axios.post('/user/clearStatistics')
    }

    getPayments(page = 1, limit = 25) {
        return axios.get(`/payments?page=${page}&limit=${limit}`)
    }

    getPaymentVariants(id, coupon = null) {
        if (coupon) return axios.get(`/checkout/${id}/variants?coupon=${coupon}`)
        return axios.get(`/checkout/${id}/variants`)
    }

    payCheckout(id, ...args) {
        return axios.post(`/checkout/${id}/pay`, ...args)
    }

    getPaymentMethods(id) {
        return axios.get(`/checkout/${id}/paymentMethods`)
    }
    stripePaymentConfirm(id) {
        return axios.get(`/stripePayment/${id}/confirm`)
    }
    checkPaymentCoupon(id, coupon) {
        return axios.get(`/checkout/${id}/checkCoupon/${coupon}`)
    }

    getSubscriptions(page = 1, limit = 25) {
        return axios.get(`/subscriptions?page=${page}&limit=${limit}`)
    }

    changeCard(id, cardId) {
        return axios.put(`/subscriptions/${id}/changeCard/${cardId}`)
    }

    cancelSubscriptions(id) {
        return axios.put(`/subscriptions/${id}/cancel`)
    }

    getCards() {
        return axios.get('/paymentCards')
    }

    postCards(token) {
        return axios.post('/paymentCards', token)
    }

    forgotPassword(...args) {
        return axios.post('/forgot-password', ...args)
    }

    resetPassword(token, ...args) {
        return axios.post(`/reset-password/${token}`, ...args)
    }

    sendCart(...args) {
        return axios.post('/coupons/add', ...args)
    }

    getTipsters(query) {
        return axios.get(`/tipsters?${query}`)
    }

    getStripeStatus() {
        return axios.get(`/partners`)
    }

    getStripeJoinUrl() {
        return axios.post(`/partners/join`)
    }

    getPartnersStats() {
        return axios.get('/jv/settings')
    }

    getPartners(page = 1, limit = 25) {
        return axios.get(`/jv/users?page=${page}&limit=${limit}`)
    }

    paidOffCoupon(couponId, ...data) {
        return axios.put(`/coupons/${couponId}/paidOff`, ...data)
    }

    getDashboard() {
        return axios.get(`/dashboards`)
    }
    getDashboardBalance() {
        return axios.get(`/dashboards/balance`)
    }

    getReportFeed(feedId) {
        return axios.get(`/reportFeed/getReasonsForFeed/${feedId}`)
    }

    postReportFeed(...params) {
        return axios.post(`/reportFeed`, ...params)
    }

    getAdminReportFeedList(page) {
        return axios.get(`/admin/getPostReports?page=${page}`)
    }
    deleteAdminReportFeed(id) {
        return axios.delete(`/admin/deleteReportAndFeedItem/${id}`)
    }

    getFeedComments(feedId, page = 1, limit = 25) {
        return axios.get(`/feeds/${feedId}/comments?page=${page}&limit=${limit}`)
    }

    postFeedComments(feedId, ...params) {
        return axios.post(`/feeds/${feedId}/comments`, ...params)
    }
    deleteFeedComment(feedId, commentId) {
        return axios.delete(`/feeds/${feedId}/comments/${commentId}`)
    }
    getSearchUsers(parameters) {
        return axios.get(`/users`, { params: parameters })
    }

    postLikedFeedComments(feedId, commentId) {
        return axios.post(`/feeds/${feedId}/comments/${commentId}/like`)
    }

    deleteLikedFeedComments(feedId, commentId) {
        return axios.delete(`/feeds/${feedId}/comments/${commentId}/like`)
    }
    addFeedPost(file) {
        return axios.post('/feeds', file, {
            headers: {
                'content-type': file.type,
                'content-length': `${file.size}`
            }
        })
    }

    deleteFeed(feedId) {
        return axios.delete(`/feeds/${feedId}`)
    }

    getPaidTipsterSettings() {
        return axios.get(`/user/paidTipster`)
    }

    updatePaidTipsterSettings(...params) {
        return axios.put(`/user/paidTipster`, ...params)
    }
    addPaidTipsterSettings(...params) {
        return axios.post(`/user/paidTipster`, ...params)
    }

    getIsMaintenance() {
        return axios.get('maintenance')
    }

    getUserGeoLocalization() {
        return axios.get('user/geoLocalization')
    }

    postFeedContVisit(feedId) {
        return axios.post('feeds/' + feedId + '/countVisit')
    }

    postMixPanelEvent(event, data) {
        return axios.post('mixpanel/track', { event, data })
    }

    searchMatches(search) {
        return axios.get(`search/getMatches/${search}`)
    }
    registerConfirm(token) {
        return axios.post(`/register-confirm`, { token })
    }

    getTipstersWinning() {
        return axios.get('tipsters/lastWinning')
    }

    getFeedBanners(code) {
        return axios.get(`/banners/getBannersForCountry/${code}`)
    }
    getAdminAllBanners() {
        return axios.get(`/admin/banners/getAllBanners`)
    }

    getAdminAllTournaments() {
        return axios.get(`/admin/tournaments`)
    }

    getAdminTournamentRounds(tournamentId) {
        return axios.get(`/admin/tournaments/${tournamentId}/rounds`)
    }
    getAdminTournamentRoundTasks(tournamentId, roundId) {
        return axios.get(`/admin/tournaments/${tournamentId}/rounds/${roundId}/tasks`)
    }
    activateAdminTournamentRoundTasks(taskId, isActive) {
        return axios.put(`/admin/tournamentRoundTasks/${taskId}/activate`, {isActive})
    }
    deleteAdminTournamentRoundTasks(taskId) {
        return axios.delete(`/admin/tournamentRoundTasks/${taskId}`)
    }

    addAdminTournamentRoundTask(tournamentId, roundId, params) {
        return axios.post(`/admin/tournaments/${tournamentId}/rounds/${roundId}/tasks`, {...params})
    }

    adminActivateBanner(id, code) {
        return axios.post(`/admin/banners/${id}/setAsEnabled`, { countryCode: code })
    }

    adminDeleteBanner(id) {
        return axios.delete(`/admin/banners/deleteBanner/${id}`)
    }

    adminAddBanner(file) {
        return axios.post('/admin/banners/uploadNewBanner', file, {
            headers: {
                'content-type': file.type,
                'content-length': `${file.size}`
            }
        })
    }
    getActiveRoundOfTournament() {
        return axios.get(`/tournaments/active`)
    }

    addAdminTournamentRounds(tournamentId, params) {
        return axios.post(`/admin/tournaments/${tournamentId}/rounds`, {...params})
    }

    settleAdminTournamentRoundTask(taskId) {
        return axios.post(`/admin/tournamentRoundTasks/${taskId}/settleTask`)
    }

    activateAdminTournamentRound(roundId, isActive) {
        return axios.put(`/admin/tournamentRounds/${roundId}/activate`, {isActive})
    }
    deleteAdminTournamentRounds(roundId) {
        return axios.delete(`/admin/tournamentRounds/${roundId}`)
    }

    getTournamentActiveMyOwnUser() {
        return axios.get(`/tournaments/active/users/myOwnUser`)
    }

    joinToTournamentActive(params) {
        return axios.post(`/tournaments/active/users`, {...params})
    }

    postAnswerToActiveTournament(params) {
        return axios.post(`/tournaments/active/answers`, {...params})
    }

    settleAdminTournamentRound(tournamentId, roundId) {
        return axios.post(`/admin/tournaments/${tournamentId}/rounds/${roundId}/settleRound`)
    }

    getRankingActiveTournament(page) {
        return axios.get(`/tournaments/active/scores?page=${page}`)
    }
    getMyOwnScoreActiveTournament() {
        return axios.get(`/tournaments/active/myOwnScore`)
    }

    getTournamentsRounds(tournamentId) {
        return axios.get(`/tournaments/${tournamentId}/rounds`)
    }

    getRankingTournament(torunamentId, page) {
        return axios.get(`/tournaments/${torunamentId}/scores?page=${page}`)
    }

    getMyOwnScoreTournament(tournamentId) {
        return axios.get(`/tournaments/${tournamentId}/myOwnScore`)
    }

    postAnswerToTournament(tournamentId, roundId, params) {
        return axios.post(`/tournaments/${tournamentId}/rounds/${roundId}/answers`, {...params})
    }

    getUserRankProgress() {
        return axios.get(`/user/rankProgress`)
    }

    getSuggestionStake() {
        return axios.get(`/user/suggestionStake`)
    }

    putSuggestionStake(data) {
        return axios.put(`/user/suggestionStake`, {...data})
    }

    getAdminUsers(page, username = null) {
        if (username) return axios.get(`/admin/users?page=${page}&username=${username}`)
        return axios.get(`/admin/users?page=${page}`)
    }

    getAdminUser(id) {
        return axios.get(`/admin/users/${id}`)
    }

    postCustomRank(data) {
        return axios.post(`/admin/user-custom-rank`, {...data})
    }

    putCustomRank(id, data) {
        return axios.put(`/admin/user-custom-rank/${id}`, {...data})
    }

    getCustomRanks() {
        return axios.get(`/admin/user-custom-rank`)
    }

    getCustomRanksId(id) {
        return axios.get(`/admin/user-custom-rank/${id}`)
    }

    setRankToUser(userId, rankId) {
        return axios.post(`/admin/users/${userId}/customRank`, { rank_id: rankId })
    }

    deleteRankToUser(userId, rankId) {
        return axios.delete(`/admin/users/${userId}/customRank/${rankId}`)
    }

    getCouponGenerator(value) {
        return axios.get(`/couponGenerator/generate?expectedOddValue=${value}`)
    }

    acceptCouponGeneratorProposal(proposalId) {
        return axios.post(`/couponGenerator/proposal/${proposalId}/accept`)
    }

    declineCouponGeneratorProposal(proposalId) {
        return axios.post(`/couponGenerator/proposal/${proposalId}/reject`)
    }

    getUserWarnings(id) {
        return axios.get(`/users/${id}/warnings`)
    }

    getGlobalNotes() {
        return axios.get(`/globalNotes`)
    }
    putGlobalNotesRead(noteId) {
        return axios.put(`/globalNotes/${noteId}/read`)
    }

    getWarningReasons() {
        return axios.get(`/warningReasons`)
    }
    getRestrictionsReasons() {
        return axios.get(`/restrictions`)
    }

    postAdminUserWarning(userId, data) {
        return axios.post(`/admin/users/${userId}/warnings`, {...data})
    }

    postAdminUserRestrictions(userId, data) {
        return axios.post(`/admin/users/${userId}/restrictions`, {...data})
    }

    getChatRooms() {
        return axios.get(`/chatRooms`)
    }

    getChats(roomId, page = 1, limit = 25) {
        return axios.get(`/chatRooms/${roomId}/messages?page=${page}&limit=${limit}`)
    }

    deleteChat(chatId) {
        return axios.delete(`/chats/${chatId}`)
    }

    sendChatMessage(roomId, message, prevMessageId = null) {
        return axios.post(`/chatRooms/${roomId}/messages`, {
            type: 'text_message',
            text: message,
            prevMessageId
        })
    }

}
